import {AbstractControl, FormArray, ValidatorFn} from '@angular/forms';

export class CustomValidator {
  static urlValidator(url: AbstractControl): object | void {
    if (url.pristine) {
      return;
    }
    // tslint:disable-next-line:max-line-length
    const urlRegExp = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))([a-zA-Z0-9_-]\.?)+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;
    url.markAsTouched();
    if (!urlRegExp.test(url.value)) {
      return {
        invalidUrl: true
      };
    }
  }

  static requireCheckboxesToBeCheckedValidator(minRequired = 1): ValidatorFn {
    return function validate(form: FormArray) {
      if (form.controls.length < minRequired) {
        return {
          requireCheckboxesToBeChecked: true,
        };
      }
      return null;
    };
  }

  static passwordMatchValidator(control: AbstractControl): void {
    const password: string = control.get('password').value;
    const confirmPassword: string = control.get('confirmPassword').value;
    if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({passwordMismatch: true});
    }
  }

  static numberOnlyValidator(value: AbstractControl): object | void {
    if (value.pristine) {
      return;
    }
    value.markAsTouched();
    if (!/^[1-9][0-9]*$/.test(value.value)) {
      return {
        invalidNumberOnly: true
      };
    }
  }

/*  static numberValidator(value: AbstractControl): object | void {
    if (value.pristine) {
      return;
    }
    value.markAsTouched();
    if (!/^-?[\d.]+(?:e-?\d+)?$/.test(value.value)) {
      return {
        invalidNumber: true
      };
    }
  }

  static ssnValidator(ssn: AbstractControl): object | void {
    if (ssn.pristine) {
      return;
    }
    const ssnRegExp = /^(?!219-09-9999|078-05-1120)(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
    ssn.markAsTouched();
    if (!ssnRegExp.test(ssn.value)) {
      return {
        invalidSsn: true
      };
    }
  }

  static phoneValidator(value: AbstractControl): object | void {
    if (value.pristine) {
      return;
    }
    value.markAsTouched();
    if (!/^[1-9][0-9]{3,14}$/.test(value.value)) {
      return {
        invalidNumber: true
      };
    }
  }

  static passwordSpecialValidator(value: AbstractControl): object | void {
    if (value.pristine) {
      return;
    }
    const regExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    value.markAsTouched();
    if (!regExp.test(value.value)) {
      return {
        invalidNumber: true
      };
    }
  }

  static zipCodeValidator(zip: AbstractControl): object | void {
    if (zip.pristine) {
      return;
    }
    zip.markAsTouched();
    if (!/^[0-9]{5}(?:-[0-9]{4})?$/.test(zip.value)) {
      return {
        invalidZip: true
      };
    }
  }

  static stringValidator(value: AbstractControl): object | void {
    if (value.pristine) {
      return;
    }
    value.markAsTouched();
    if (!/^[a-zA-Z-_ ]*$/.test(value.value)) {
      return {
        stringOnly: true
      };
    }
  }

  static formatTimeValidator(value: AbstractControl): object | void {
    if (value.pristine) {
      return;
    }
    const timeRegExp = /^(1[0-2]|0?[1-9]):([0-5]?[0-9])$/;
    value.markAsTouched();
    if (!timeRegExp.test(value.value)) {
      return {
        invalidTimeFormat: true
      };
    }
  }

  static numberGreaterThanZeroValidator(value: AbstractControl): object | void {
    if (value.pristine) {
      return;
    }
    value.markAsTouched();
    if (!/^[1-9][0-9]*$/.test(value.value)) {
      return {
        invalidNumber: true
      };
    }
  }

  static decimalPrecisionValidator(value: AbstractControl): object | void {
    if (value.pristine) {
      return;
    }
    value.markAsTouched();
    if (!/\d+(\.\d{1,2})?/.test(value.value)) {
      return {
        invalidDecimal: true
      };
    }
  }*/
}
