import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './auth/login/login.component';
import { RecoveryPasswordComponent } from './auth/recovery-password/recovery-password.component';
import { SharedModule } from './common/common.module';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpConfigInterceptor } from './interceptor/httpconfig.interceptor';
import { AuthService } from './http-services/auth.service';
import { BlobModule } from 'angular-azure-blob-service';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { DeactivateGuard } from './guard/deactivate-guard';
import { DeactivateGuard as DeactivateGuard2 } from './guard/editUser-deactive-guard';
import {PubnubToastComponent} from './pubnub.toast';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import { LoadingComponent } from './common/loading/loading.component';
import {EventEmitterService} from './event-emitter.service';

Sentry.init({
  dsn: 'https://28e02e0504f24567819d7e1a9b0945fc@o576756.ingest.sentry.io/5730882',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: ['https://wowmi-dev.onza.me/api/v1'],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RecoveryPasswordComponent,
    ResetPasswordComponent,
    PubnubToastComponent,
    LoadingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ToastrModule.forRoot({
      timeOut: 3000
    }),
    SharedModule,
    BlobModule.forRoot()
  ],
  entryComponents: [PubnubToastComponent],
  providers: [
    AuthService,
    EventEmitterService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpConfigInterceptor,
      multi: true
    },
    DeactivateGuard,
    DeactivateGuard2,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {
      },
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
