import { Component, OnInit } from '@angular/core';
import {CommonService} from '../../http-services/common.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  animations: [
    trigger('toggle', [
      transition(':enter', [
        style({opacity: 0}),
        animate('200ms', style({opacity: 1}))
      ]),
      transition(':leave', [
        animate('200ms', style({opacity: 0}))
      ])
    ])
  ]
})
export class LoadingComponent implements OnInit {

  constructor(public commonService: CommonService) { }

  ngOnInit() {
  }

}
