import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/http-services/auth.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidator } from 'src/app/locales/validators';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/http-services/common.service';
import { AppConstant } from 'src/app/locales/app.constants';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html'
})
export class ResetPasswordComponent implements OnInit {
  resetForm: FormGroup;
  errorMessage = '';
  isShowPassword = false;
  isShowConfirmPassword = false;
  emailId: string;

  constructor(private formBuilder: FormBuilder,
              private authService: AuthService,
              private activatedRoute: ActivatedRoute,
              private commonService: CommonService,
              private router: Router) {
    this.emailId = this.activatedRoute.snapshot.queryParams.uuid;
  }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.resetForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, {
        validators: CustomValidator.passwordMatchValidator
      }
    );
  }

  get form() {
    return this.resetForm.controls;
  }

  onSubmit() {
    if (this.resetForm.invalid) { return; }

    this.commonService.showLoader();

    this.authService.resetPassword(this.emailId, this.form.password.value).subscribe((res: any) => {
      if (res.statusCode === AppConstant.HttpStatus.Success && res.status) {
        this.commonService.showSuccess(res.message);
        this.router.navigateByUrl('login');
      }
      this.commonService.hideLoader();
    }, (error: any) => {
      this.errorMessage = error.message;
      this.commonService.hideLoader();
      this.commonService.showError('Something went wrong');
    });
  }
}
