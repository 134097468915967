import { Component } from '@angular/core';
import { AuthService } from 'src/app/http-services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/http-services/common.service';
import { AppConstant } from 'src/app/locales/app.constants';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  loginForm = new FormGroup({
    email: new FormControl('', Validators.compose([Validators.required, Validators.email])),
    password: new FormControl('', Validators.compose([Validators.required]))
  });
  currentUser: any;
  errorMessage = '';
  isShowPassword = false;

  constructor(private authService: AuthService, private commonService: CommonService, private router: Router) {
    const currentUser = JSON.parse(localStorage.getItem('currentAdmin'));
    if (currentUser instanceof Object && currentUser.access_token) {
      this.router.navigateByUrl('landing');
    }
  }

  get form() { return this.loginForm.controls; }

  onSubmit() {
    let password = '';
    if (this.loginForm.invalid) {
      return;
    }
    password = this.form.password.value;
    this.commonService.showLoader();
    this.authService.login(this.form.email.value, password)
      .subscribe((res: any) => {
        if (res.statusCode === AppConstant.HttpStatus.Success && res.status) {
          this.router.navigateByUrl('landing');
          this.commonService.showSuccess('Login Successfully.');
        }
        this.commonService.hideLoader();
      }, (error: any) => {
        this.errorMessage = error.message;
        this.commonService.hideLoader();
        this.commonService.showError('Invalid credentials.');
      });
  }

}
