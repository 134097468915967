import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { CurrentUser } from '../signature';
import { ToastrService } from 'ngx-toastr'; // toast messages
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public isLoading = new BehaviorSubject(false);
  httpHeaders: HttpHeaders;
  public currentUserSubject: BehaviorSubject<CurrentUser>;
  public currentUser: Observable<CurrentUser>;

  public currentGroupIdSubject: BehaviorSubject<any>;
  public modalCloseSubject: BehaviorSubject<any>;

  constructor(private toastr: ToastrService, private router: Router) {

    this.currentUserSubject = new BehaviorSubject<CurrentUser>(JSON.parse(localStorage.getItem('currentAdmin')));
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentGroupIdSubject = new BehaviorSubject<[]>(null);
    this.modalCloseSubject = new BehaviorSubject<[]>(null);
  }

  public get currentUserValue(): CurrentUser {
    return JSON.parse(localStorage.getItem('currentAdmin')) as CurrentUser;
  }

  public handleError(err) {
    if (err.statusText) {
      this.showError(err.statusText);
    }
    return throwError(err);
  }

  showSuccess(message: string) {
    this.toastr.success(message);
  }

  showError(message: string) {
    this.toastr.error(message);
  }

  showLoader() {
    this.isLoading.next(true);
  }

  hideLoader() {
    this.isLoading.next(false);
  }

}
