export const Routes = {
  Common: {
    Login: '/login',
    RefreshToken: '/access-token',
    ForgotPassword: '/recovery-password-password',
    ResetPassWord: '/reset-password',
    azureSasToken: '/azure-sas-token'
  },
  UserManagement: {
    CustomerList: '/users-list',
    GroupList: '/groups',
    SearchList: '/search',
    AddCustomer: '/user',
    UpdateCustomer: '/user',
    UpdateCustomerStatus: '/account-status',
    AddCustomerGroup: '/user-group',
    CustomerProfile: '/user-details',
    DeleteCustomer: '/users',
    BlockUnblockCustomer: '/user-status',
    GetCountryCodes: '/country-codes',
    RoleTypes:'/user-role-types'
  },
  GroupManagement: {
    GroupList: '/groups-list',
    GroupMember: '/group-members',
    GroupMemberLocationWise: '/locationwise-members',
    GroupRegion: '/group-locations',
    AddGroup: '/group',
    CopyGroup: '/group-copy',
    RemoveGroup: '/delete-groups',
    AddBrand: '/group-brand',
    AddModule: '/group-modules',
    removeModule: '/module',
    AddContent: '/module-content',
    GetGroupModule: '/group-modules',
    GetGroupModuleContents: '/module-content',
    RemoveModuleContents: '/module-content',
    GetContentTypes: '/content-types',
    GetGroupDetails: '/group-details',
    GetContentDetails: '/content-details',
    GetModuleDetails: '/module-details',
    UpdateGroup: '/group',
    createChannel: '/chat-channel',
    getChannel: '/chat-channels',
    ChannelList: '/chat-channels',
    ChannelDetails: '/chat-channel-details',
    DeleteChannel: '/chat-channels',
    messageRead: '/msg-read-time',
    GetGroupMarketUpdateModule: '/market-update-module/:id',
    GetGroupMarketUpdateModuleList: '/group/:gid/market-update-module',
    RemoveGroupMarketUpdateModule: '/market-update-module/:id',
    UpdateGroupMarketUpdateModule: '/market-update-module/:id',
    CreateGroupMarketUpdateModule: '/group/:gid/market-update-module'
  },
  ReportManagement: {
    ReportList: '/reports-list',
    ReportDetails: '/report-details',
    UpdateReportStatus: '/report-status'
  },
  VotingManagement: {
    VotingList: '/voting'
  },
  MessageManagement: {
    MessageList: '/messages-list',
    MessageDetails: '/message-details',
    DeleteMessage: '/messages',
    SendMessage: '/send-message',
    resendMessage: '/resend-message',
    sendMessageToNewUser: '/send-message-new-user'
  },
  WowmiMessageManagement: {
    Get: '/wowmi-message/:id',
    List: '/wowmi-message',
    Remove: '/wowmi-message/:id',
    Update: '/wowmi-message/:id',
    Create: '/wowmi-message'
  },
  ImportUserManagement: {
    ImportUser: '/import-user'
  }
};
