import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import {Observable, throwError, BehaviorSubject} from 'rxjs';
import {catchError, switchMap, take, filter} from 'rxjs/operators';
import {AppConstant} from '../locales/app.constants';
import {AuthService} from '../http-services/auth.service';
import {environment} from '../../environments/environment';
import {CurrentUser} from '../signature';
import {CommonService} from '../http-services/common.service';

/** Pass untouched request through to the next request handler. */

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private authenticationService: AuthService, private commonService: CommonService, private router: Router) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const userData = (JSON.parse(localStorage.getItem('currentAdmin'))) as CurrentUser;

    if (userData != null && userData.access_token) {
      if (request.body instanceof FormData) {
        request = request.clone({
          setHeaders: {
            access_token: userData.access_token,
           // 'Content-Type': 'multipart/form-data', // изменено на 'multipart/form-data'
            Accept: 'application/json',
            client_secret: `${environment.CLIENT_SECRET_KEY}`,
            zone_offset: `${new Date().getTimezoneOffset()}`
          }
        });
      } else {
        request = request.clone({
          setHeaders: {
            access_token: userData.access_token,
            'Content-Type': 'application/json',
            Accept: 'application/json',
            client_secret: `${environment.CLIENT_SECRET_KEY}`, // As discussed with backend guy will pass client_secret on each request
            zone_offset: `${new Date().getTimezoneOffset()}`
          }
        });
      }
    } else {
      request = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          client_secret: `${environment.CLIENT_SECRET_KEY}`,
          zone_offset: `${new Date().getTimezoneOffset()}`
        }
      });
    }

    return next.handle(request).pipe(catchError(err => {

      if (err.status === AppConstant.HttpStatus.Forbidden) {
        return this.handle403Error(request, next);
      } else if (err.status === AppConstant.HttpStatus.Unauthorized) {
        this.authenticationService.logout();
        this.router.navigateByUrl('login');
      }

      if ([401, 403].includes(err.status)) {
        this.commonService.showError(err.error.message);
      }

      return throwError(err.error);
    }));
  }

  private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authenticationService.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.result.access_token);

          this.authenticationService.currentUserValue.access_token = token.result.access_token;
          this.authenticationService.currentUserValue.refresh_token = token.result.refresh_token;
          localStorage.setItem('currentUser', JSON.stringify(this.authenticationService.currentUserValue));

          return next.handle(this.addToken(request, token.result.access_token));
        }));
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        access_token: token,
        'Content-Type': 'application/json',
        Accept: 'application/json',
        client_secret: `${environment.CLIENT_SECRET_KEY}`, // As discussed with backend guy will pass client_secret on each request
        zone_offset: `${new Date().getTimezoneOffset()}`
      }
    });
  }

}
