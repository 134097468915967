<div class="login-wrapper">
  <div class="login-wrapper__content">
    <div class="login-wrapper__logo">
      <img src="assets/images/logo.svg" alt="logo" />
    </div>
    <div class="login-wrapper__form">
      <div class="login-wrapper__title">
        Reset Password
      </div>

      <form [formGroup]="resetForm" (ngSubmit)="onSubmit()">
        <div class="text-error" *ngIf="errorMessage">{{ errorMessage }}</div>

        <div class="form-group form-group--password">
          <input
            class="input form-control"
            [ngClass]="{'is-invalid': (!form.password.pristine) && form.password.errors}"
            [type]="isShowPassword ? 'text' : 'password'"
            placeholder="Password"
            formControlName="password"
            name="password"
          />
          <i class="la" [ngClass]="isShowPassword ? 'la-eye-slash' : 'la-eye'" (click)="isShowPassword = !isShowPassword"></i>

          <div class="invalid-feedback" *ngIf="form.password.errors">
            {{ form.password.errors.required ? 'Password is required' : '' }}
          </div>
        </div>

        <div class="form-group form-group--password">
          <input
            class="input form-control"
            [ngClass]="{'is-invalid': (!form.confirmPassword.pristine) && form.confirmPassword.errors}"
            [type]="isShowConfirmPassword ? 'text' : 'password'"
            placeholder="Confirm password"
            formControlName="confirmPassword"
            name="confirmPassword"
          />
          <i class="la" [ngClass]="isShowConfirmPassword ? 'la-eye-slash' : 'la-eye'" (click)="isShowConfirmPassword = !isShowConfirmPassword"></i>

          <div class="invalid-feedback" *ngIf="form.confirmPassword.errors">
            {{ form.confirmPassword.errors.required ? 'Password is required' : 'Password mismatch' }}
          </div>
        </div>

        <button type="submit" class="btn btn--full btn--dark mt-4" [disabled]="!resetForm.valid">Reset</button>
        <button routerLink="/login" class="btn btn--full mt-2">Cancel</button>
      </form>
    </div>
  </div>
</div>
