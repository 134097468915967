export const AppConstant = {
  HttpStatus: {
    Success: 200,
    Created: 201,
    InvalidCredential: 203,
    BadRequest: 400,
    Unauthorized: 401,
    Forbidden: 403,
    NotFound: 404,
    MethodNotAllowed: 405,
    RequestTimeout: 408,
    Conflict: 409,
    LengthRequired: 411,
    InvalidToken: 498,
    TokenRequired: 499,
    ServerError: 500,
    ExpireToken: 401,
    InvalidAccessToken: 417,
    InvalidRefreshToken: 410
  },
};
