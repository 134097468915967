import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { CurrentUser } from '../signature';
import { HttpDispatcherService } from './http-dispatcher.service';
import { Routes } from '../locales/api-routes';
import { CommonService } from './common.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  httpHeaders: HttpHeaders;
  public currentUserSubject: BehaviorSubject<CurrentUser>;
  public currentUser: Observable<CurrentUser>;

  constructor(private dispacherService: HttpDispatcherService, private commonService: CommonService) {
    this.currentUserSubject = new BehaviorSubject<CurrentUser>(JSON.parse(localStorage.getItem('currentAdmin')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): CurrentUser {
    return (JSON.parse(localStorage.getItem('currentAdmin'))) as CurrentUser;
  }

  /**
   * For loggin in user
   * @param email email
   * @param password password
   */
  login(email: string, password: string) {
    const model = { email, password };
    return this.dispacherService.post(`${environment.API_BASE_URL.userService}${Routes.Common.Login}`, model)
      .pipe(map((data: any) => {
        if (data && data.result.access_token && data.result.refresh_token) {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentAdmin', JSON.stringify(data.result as CurrentUser));
          this.currentUserSubject.next(data.result as CurrentUser);
        }
        return data;
      }), catchError(err => this.commonService.handleError(err)));
  }

  /**
   * For reset admin password
   * @param email email
   * @param password new password
   */
  resetPassword(email: string, password: string) {
    return this.dispacherService.put(`${environment.API_BASE_URL.userService}${Routes.Common.ResetPassWord}`,
      { email, password })
      .pipe(map(data => {
        return data;
      }), catchError(err => this.commonService.handleError(err)));
  }

  forgotPassword(email: string) {
    return this.dispacherService.post(`${environment.API_BASE_URL.userService}${Routes.Common.ForgotPassword}`,
      { email })
      .pipe(map(data => {
        return data;
      }), catchError(err => this.commonService.handleError(err)));
  }

  refreshToken() {
    let data;
    if (localStorage.getItem('currentAdmin')) {
      data = JSON.parse(localStorage.getItem('currentAdmin'));
    }
    const req = {
      refresh_token: data.refresh_token,
      admin_id: data.admin_id,
    };
    return this.dispacherService.post(`${environment.API_BASE_URL.userService}${Routes.Common.RefreshToken}`, req)
      // tslint:disable-next-line:no-shadowed-variable
      .pipe(map((data: any) => {
        const currentUser = this.currentUserValue;
        if (currentUser != null) {
          currentUser.access_token = data.result.access_token;
          localStorage.setItem('currentAdmin', JSON.stringify(currentUser));
          this.currentUserSubject.next(currentUser);
        }
        return data;
      }), catchError(err => this.commonService.handleError(err)));
  }

  logout() {
    if (localStorage.getItem('currentAdmin')) {
      localStorage.removeItem('currentAdmin');
    }
  }

}
