import { Injectable } from '@angular/core';
import { HttpDispatcherService } from './http-dispatcher.service';
import { CommonService } from './common.service';
import {HttpClient} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Routes } from '../locales/api-routes';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  constructor(private dispacherService: HttpDispatcherService, private httpClient: HttpClient, private commonService: CommonService) { }

  /* Get Group List */
  getGroupList(pageNo: number, pageSize: number, searchKey = '') {
    return this.dispacherService.get(`${environment.API_BASE_URL.groupService}${Routes.GroupManagement.GroupList}`,
      { page: pageNo, items_per_page: pageSize, search_text: searchKey })
      .pipe(
        catchError((err) => this.commonService.handleError(err))
      );
  }

  /* Get Group Member List */
  getGroupMemberList(groupId: number, searchKey = '', isTag = false, isChat = true) {
    return this.dispacherService.get(`${environment.API_BASE_URL.groupService}${Routes.GroupManagement.GroupMember}`,
      { group_id: groupId, is_chat: isChat, search_text: searchKey, is_tag: isTag })
      .pipe(
        catchError((err) => this.commonService.handleError(err))
      );
  }

  // For saving pubnub channels in wowmi db
  updateTimetoken(body) {
    return this.dispacherService.put(`${environment.API_BASE_URL.groupService}${Routes.GroupManagement.messageRead}`, body)
      .pipe(
        catchError((err) => this.commonService.handleError(err))
      );
  }

  // For saving pubnub channels in wowmi db
  createChannels(body) {
    return this.dispacherService.post(`${environment.API_BASE_URL.groupService}${Routes.GroupManagement.createChannel}`, body)
      .pipe(
        catchError((err) => this.commonService.handleError(err))
      );
  }

  // For getting channels list
  getChannelList(searchKey: string) {
    return this.httpClient.get(`${environment.API_BASE_URL.groupService}${Routes.GroupManagement.ChannelList}`, {
      params: {
        search_text: searchKey
      }
    }).pipe(
        catchError((err) => this.commonService.handleError(err))
      );
  }

  // For getting channels list
  getChannelDetail(groupId: number) {
    return this.dispacherService.get(`${environment.API_BASE_URL.groupService}${Routes.GroupManagement.ChannelDetails}`,
      { group_id: groupId })
      .pipe(
        catchError((err) => this.commonService.handleError(err))
      );
  }
  /* Delete Channel */
  deleteChannel(channelId) {
    return this.dispacherService.delete(`${environment.API_BASE_URL.groupService}${Routes.GroupManagement.DeleteChannel}`,
      { channels: `${JSON.stringify(channelId)}` })
      .pipe(
        catchError((err) => this.commonService.handleError(err))
      );
  }
}
