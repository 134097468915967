import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { EditUserComponent } from '../feature-module/user-management/edit-user/edit-user.component';

export class DeactivateGuard implements CanDeactivate<EditUserComponent> {

    canDeactivate(component: EditUserComponent, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        if (component.getObservableValue() && component.getObservableValue() === 1) {
            return component.canDeactivate(2, nextState.url);
        } else {
            return component.canDeactivate(1, nextState.url);
        }
    }

}