
    <a [routerLink]="'/landing/chat'" class="new-message-link" [style.display]="state.value === 'inactive' ? 'none' : ''">
      <div class="new-message">
        <div class="new-message__image" [style.background-image]="userImage"></div>
        <div class="new-message__content">
          <div class="new-message__username" *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">{{ title }}</div>
          <div class="new-message__message">New message!</div>
        </div>
      </div>
    </a>
    <div *ngIf="options.progressBar">
      <div class="toast-progress" [style.width]="width + '%'"></div>
    </div>
  