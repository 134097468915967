import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { AddUserComponent } from '../feature-module/user-management/add-user/add-user.component';

export class DeactivateGuard implements CanDeactivate<AddUserComponent> {

    canDeactivate(component: AddUserComponent, currentRoute: ActivatedRouteSnapshot,
                  currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot) {
        if (component.getObservableValue('modal') && component.getObservableValue('modal') === 1) {
            return component.canDeactivate(2, nextState.url);
        } else {
            if (component.getObservableValue('group') && component.getObservableValue('group').groupId) {
                // group assigned successfully
                return component.canDeactivate(3, nextState.url);
            } else {
                return component.canDeactivate(1, nextState.url);
            }
        }
    }

}
