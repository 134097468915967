import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class HttpDispatcherService {

  constructor(private httpClient: HttpClient) { }

  /**
   * get function
   * @param url;
   * @param params;
   * @param headers;
   */
  get(url: string, params: any = {}, headers = {}) {
    return this.httpClient.get(url, { headers, params });
  }

  /**
   * post function
   * @param url;
   * @param body;
   * @param headers;
   */
  post(url: string, body: any = {}, headers = {}) {
    return this.httpClient.post(url, body, { headers });
  }

  /**
   * put function
   * @param url;
   * @param body;
   * @param headers;
   */
  put(url: string, body: any = {}, headers = {}) {
    return this.httpClient.put(url, body, { headers });
  }

  /**
   * delete function
   * @param url;
   * @param params;
   * @param headers;
   */
  delete(url: string, params: any = {}, headers = {}) {
    return this.httpClient.delete(url, { headers, params });
  }

}
