<div class="login-wrapper">
  <div class="login-wrapper__content">
    <div class="login-wrapper__logo">
      <img src="assets/images/logo.svg" alt="logo" />
    </div>
    <div class="login-wrapper__form">
      <div class="login-wrapper__title">
        Sign in
      </div>

      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="text-error" *ngIf="errorMessage">{{ errorMessage }}</div>

        <div class="form-group">
          <input
            class="input form-control"
            [ngClass]="{'is-invalid': (!form.email.pristine) && form.email.errors}"
            type="email"
            placeholder="Email"
            formControlName="email"
            name="email"
          />
          <div class="invalid-feedback" *ngIf="form.email.errors">
            {{ form.email.errors.required ? 'Email is required' : 'Email format is not valid' }}
          </div>
        </div>

        <div class="form-group form-group--password">
          <input
            class="input form-control"
            [ngClass]="{'is-invalid': (!form.password.pristine) && form.password.errors}"
            [type]="isShowPassword ? 'text' : 'password'"
            placeholder="Password"
            formControlName="password"
            name="password"
          />
          <i class="la" [ngClass]="isShowPassword ? 'la-eye-slash' : 'la-eye'" (click)="isShowPassword = !isShowPassword"></i>

          <div class="invalid-feedback"
               *ngIf="form.password.errors">
            {{ form.password.errors.required ? 'Password is required' : '' }}
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <a [routerLink]="'/recovery-password'" class="text-turq animate-line animate-line--turq">
            Forgot Password?
          </a>
        </div>

        <button type="submit" class="btn btn--full btn--dark mt-4" [disabled]="!loginForm.valid">Log in</button>
      </form>
    </div>
  </div>
</div>
