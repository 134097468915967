import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { NgxPaginationModule } from 'ngx-pagination';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { PubNubAngular } from 'pubnub-angular2';
import { TimeAgoPipe } from 'time-ago-pipe';

import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ConfirmModalComponent } from './confirm-modal/confirm-modal.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { TagInputModule } from 'ngx-chips';
import { ColorPickerModule } from 'ngx-color-picker';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { FullscreenComponent } from './fullscreen/fullscreen.component';
import { GroupByPipe, SafeHtmlPipe } from './pipes/groupby.pipe';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ConfirmCancelModalComponent } from './confirm-cancel-modal/confirm-cancel-modal.component';
import { EnterPasswordModalComponent } from './enter-password-modal/enter-password-modal.component';
import {TabsHeaderComponent} from './tabs-header/tabs-header.component';
import {RouterModule} from '@angular/router';
import {TableComponent} from './table/table.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
export const MY_NATIVE_FORMATS = {
  fullPickerInput: { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' },
  datePickerInput: { year: 'numeric', month: 'numeric', day: 'numeric' },
  timePickerInput: { hour: 'numeric', minute: 'numeric' },
  monthYearLabel: { year: 'numeric', month: 'short' },
  dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
  monthYearA11yLabel: { year: 'numeric', month: 'long' },
};

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    TimeAgoPipe,
    GroupByPipe,
    SafeHtmlPipe,
    PageNotFoundComponent,
    ConfirmModalComponent,
    AutocompleteComponent,
    FullscreenComponent,
    ConfirmCancelModalComponent,
    AlertModalComponent,
    EnterPasswordModalComponent,
    TabsHeaderComponent,
    TableComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    PerfectScrollbarModule,
    InfiniteScrollModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    OwlDateTimeModule,
    TagInputModule,
    OwlNativeDateTimeModule,
    ColorPickerModule,
    AutocompleteLibModule,
    ImageCropperModule,
    NgMultiSelectDropDownModule,
    RouterModule
  ],
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_NATIVE_FORMATS },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    PubNubAngular
  ],
  exports: [
    CommonModule, FormsModule, ReactiveFormsModule, PerfectScrollbarModule, InfiniteScrollModule, HttpClientModule, TimeAgoPipe,
    NgxPaginationModule, NgMultiSelectDropDownModule, HeaderComponent, FooterComponent, ConfirmModalComponent, PageNotFoundComponent,
    OwlDateTimeModule, OwlNativeDateTimeModule, TagInputModule, ColorPickerModule, AutocompleteLibModule,
    FullscreenComponent, AutocompleteComponent, GroupByPipe, SafeHtmlPipe, ImageCropperModule, ConfirmCancelModalComponent, AlertModalComponent,
    EnterPasswordModalComponent, TabsHeaderComponent, TableComponent
  ]
})
export class SharedModule { }
