import {EventEmitter, Injectable} from '@angular/core';
import {Subscription} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventEmitterService {

  invokeGroupChatComponentFunc = new EventEmitter();
  subsVar: Subscription;

  constructor() { }

  onNewMessage() {
    this.invokeGroupChatComponentFunc.emit();
  }
}
