<div class="login-wrapper">
  <div class="login-wrapper__content">
    <div class="login-wrapper__logo">
      <img src="assets/images/logo.svg" alt="logo" />
    </div>
    <div class="login-wrapper__form">
      <div class="login-wrapper__title mb-0">Forgot password?</div>
      <div class="login-wrapper__text mb-4">We will send a recovery link to your email.</div>
      <form [formGroup]="recoveryForm" (ngSubmit)="onSubmit()">
        <div class="text-error" *ngIf="errorMessage">{{ errorMessage }}</div>
        <div class="form-group">
          <input
            class="input form-control"
            [ngClass]="{'is-invalid': (!form.email.pristine)  && form.email.errors }"
            type="email"
            placeholder="Email"
            formControlName="email"
            name="email"
            autocomplete="off"
          />
          <div
            class="invalid-feedback"
            *ngIf="(!form.email.pristine) && form.email.errors">
            {{ form.email.errors.required? 'Email is required':'Email format is not valid' }}
          </div>
        </div>

        <button type="submit" class="btn btn--full btn--dark mt-4" [disabled]="!recoveryForm.valid">
          Send me a recovery link
        </button>
        <button [routerLink]="'/login'" class="btn btn--full mt-2">Cancel</button>
      </form>

    </div>
  </div>
</div>
