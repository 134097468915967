import { Component } from '@angular/core';
import { AuthService } from 'src/app/http-services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/http-services/common.service';
import { AppConstant } from 'src/app/locales/app.constants';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html'
})
export class RecoveryPasswordComponent {
  recoveryForm = new FormGroup({
    email: new FormControl('', Validators.compose([Validators.required, Validators.email]))
  });
  errorMessage = '';

  constructor(private authService: AuthService, private commonService: CommonService, private router: Router) { }

  get form() { return this.recoveryForm.controls; }

  onSubmit() {
    if (this.recoveryForm.invalid) {
      return;
    }
    this.commonService.showLoader();
    this.authService.forgotPassword(this.form.email.value)
      .subscribe((res: any) => {
        if (res.status && res.statusCode === AppConstant.HttpStatus.Success) {
          this.commonService.showSuccess(res.message);
          this.router.navigateByUrl('login');
        }
        this.commonService.hideLoader();
      }, (error: any) => {
        this.errorMessage = error.message;
        this.commonService.hideLoader();
      });
  }

}
