import {Component, OnInit} from '@angular/core';
import { ChatService } from './http-services/chat.service';
import { PubNubAngular } from 'pubnub-angular2';
import {environment} from '../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {PubnubToastComponent} from './pubnub.toast';
import {Router} from '@angular/router';
import {EventEmitterService} from './event-emitter.service';

@Component({
  selector: 'app-root',
  template: '<app-loading></app-loading><router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  pubnub = new PubNubAngular();
  title = 'Wowmi Admin Panel';
  adminId = 0;

  constructor(
    private chatService: ChatService,
    private toastr: ToastrService,
    private eventEmitterService: EventEmitterService,
    private router: Router) {
    const adminInfo = JSON.parse(localStorage.getItem('currentAdmin'));
    if (adminInfo) {
      this.adminId = +adminInfo.admin_id;
    }
  }

  ngOnInit() {
    if (!this.adminId) { return; }
    this.pubnub.init({
      publishKey: environment.PUBNUBKey.publishKey,
      subscribeKey: environment.PUBNUBKey.subscribeKey,
      uuid: environment.PUBNUBKey.UUID
    });

    this.chatService.getChannelList('')
      .subscribe((res: any) => {
        const channels = [...res.result.group_channels, ...res.result.user_channels].map(item => item.channel_name);

        this.pubnub.subscribe({
          channels,
          triggerEvents: true,
          withPresence: true
        });
      });

    this.pubnub.addListener({
      message: (e) => {
        if (e.message.metadata && e.message.metadata.sender_id !== this.adminId) {
          this.toastr.show(e.message.metadata.sender_picture, e.message.metadata.sender_user_name, {
            toastComponent: PubnubToastComponent,
            toastClass: 'custom-toastr'
          });

          if (this.router.url === '/landing/chat') {
            console.log('app.component', e);
            this.eventEmitterService.onNewMessage();
          }
        }
      }
    });
  }
}
